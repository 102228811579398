.App_login {
  text-align: center;
  font-family: "Hanuman", serif;
  font-style: normal;
}
@media (max-width: 1200px) {
  .text-area_nav {
    font-size: 20px;
  }
  .text-area_list-item {
    font-size: 18px;
  }
  .header-pal {
    font-size: 24px;

  }
}
@media (max-width: 800px) {
  .text-area {
    display: none;
  }
  .header-pal {
    width: 90%;
  }
  .navigate__item {
    font-size: 14px;
  }
  .input__style {
    font-size: 12px;
  }
  .input__style label {
    font-size: 10px;
  }
  .add_table button {
    font-size: 8px;
  }
  .add_table input{
      font-size: 12px;
  }
  .search {
      font-size: 12px;
  }
 
  .work__nav-item {
    display: none;
  }
  .work__manager_list button {
    font-size: 8px;
  }
   tr .hide-on-print {
      display: flex;
      width: 100%;
      
  }
  .table tr td .table_btn {
        margin: 0 1px;
        max-height: 16px;
        max-width: 18px;
  }
  .table tr:hover td {
      background-color: #e9ecef;
      font-size: 8px;
  }
  .table_btn-copy button {
    height: 28px;
    width: 28px;
  }
  .table_btn-copy button svg {
    height: 12px;
    width: 12px;
  }
  .work__item {
    margin: 0;
  }
  .table tr {
    font-size: 7px;
  }
  .palety {
    font-size: 18px;
  }
  .table tr td {
    font-size: 8px;
  }
  li .active {
    background-color: yellow;
    text-decoration: underline;
    max-height: 16px;
  }

  .serch-chekbox {
    font-size: 12px;
  }
  .table__item {
    font-size: 12px;
  }
}

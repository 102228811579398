li {
    position: relative;
    width: 100%;
    padding: 0;
    margin-bottom: 5px;
    display: block;
    color: black;
    text-decoration: none;
    .active {
        background-color: yellow;
        height: 36px;
        text-decoration: underline;
    }
    button {
        display: block;
        width: 100%;
        text-align: center;
        font-size: 16px;
        background: white;
        border: solid 1px black;
        font-weight: 600;
        text-transform: uppercase;
    }
    button:hover{
        background-color: yellow;
        color: red;
    }
    button:before {
        content: '>';
        position: absolute;
        left: 5px;
    }
}
@media print {
    body * {
      visibility: hidden;
    }
    .document, .document *, .body-cmr, .body-cmr *, .wyrob, .wyrob *{
        visibility: visible;
        page-break-inside:auto;
        .document,tr, td {
            page-break-inside: avoid;
        }
        textarea {
            display: block;
            white-space: pre-wrap;
            border: 1px solid black;
            padding: 5px;
            font-family: inherit;
            font-size: inherit;
            line-height: inherit;
            color: #000;
}
    }
    table, table * {
      visibility: visible;
    }
    .hide-on-print {
        display: none !important;
      }
  }

 .subcount-send {
    margin: 8px 4px;
    height: 34px;
    min-width: 154px;
    border: 1px solid black;
    font-weight: 700;
    background:#ffc107;
 }
.dropdown-item:hover {
    color: red;
    background-color: #468dd56e;
}
.fixModal {
    border: 1px solid black;
    padding-top: 4px;
    width: 148px;
    height: 40px;
    font-weight:800;
    background-color: #fff267;
    position: fixed;
    right: 78px;
    top: 76px;
}
.email_modal {
    min-height: 30vh;
    width: 40vw;
    display:flex;
    flex-direction:column;
    padding:80px;
    div {
        text-align:center;
    }
    h2 {
        text-align:center;
    }
    input {
        min-height: 36px;
        width: 67%;
        margin: 10px auto;
        text-align:left;
    }
    .akcept{
        svg {
            color:green;
            height:40px;
            width:40px;
        }
        }
    button {
        margin-top: 14px;
        margin-left:20px;
        border:none;
        background-color:white;

        svg{
            color:red;
            height:44px;
            width:44px;
        }
    }
}
.copy_btn {
    width: 45px;
    margin: 0 10px;
    background-color: #0a58ca6b;
    border-radius: 10px;
    border: none;
    }
.copy_btn:hover {
    background-color:blue;
    svg{
        color:white;
    }
}
tn:hover {
    background-color: yellow;
}

.table__item{
    font-weight: 900;
    font-size: 24px;
    text-transform: uppercase;
    user-select: text;
    
}
.table_margin {
    padding-top:80px;
}
.br-right20 {
    border-radius: 12px 0 0 0;
}
.br-left20 {
    border-radius: 0 12px 0 0;
}
.fw700 {
    font-weight: 700;
}
.input__style {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: fixed;
    width: 78%;
    background-color: #0000004a;
    z-index: 99;
}
.search{
    margin-top: 4px;
    max-width:300px ;
    height: 42px;
    text-align: center;
}
.m-left{
margin-left: 10px;
}
.table__btn {
    border: none;
    padding: 0;
    svg {
        position: relative;
        top: -4px;
        transform: rotateZ(180deg);
        padding-top: 0;
        min-height: 24px;
        min-width: 24px;
        color:#1683c7;
        
    }
    svg :hover {
        color:red;
    }
}

.table__btn :hover {
    svg {
        color:red;
    }
}

.add_table {
    position: relative;
    display: flex;
    max-width: 500px;
    margin: 10px 0 5px 10px;
    height: 34px;
    input { 
        background: revert;
        margin-right: 5px;
        font-size: 18px;
        text-align: center;
        }
    button {
        background: orange;
    }
}

.search {
    margin-top:10px;
    margin-right:10px;
    height: 34px;
    max-width: 360px;
    font-size: 18px;
}
.table__serch {
    display: flex;
    flex-direction: column;
}
.serch-chekbox {
    display: flex;
    input {
        margin-right: 6px;
        margin-top: 2px;
    }
}
.serch-form {
    display: flex;
    flex-direction: column;
    margin: 10px 7px 0 0;
}
.serch-flex {
    display: flex;
}

.table__serch {
    &-radio {
        margin-top: 6px;
    }
}

.serch-form {
    display: flex;
    flex-direction: column;
    margin: 12px 7px 0 0;
}
.table {
    width: 98%;
    margin: 10px auto 0px auto;
    tr:hover td {
    transition-delay: 200ms;
    background-color: #e9ecef;
    font-size: 13px;
    }
.table__style{ 
    th {
        background: #9bbbcf;
    }
}
.checked-tr {
    td {
        background-color:#ffc107;
    }
}
.checked-tr:hover {
    td{
        background-color:yellow;
    }

}
tr {
    display: grid;
    grid-template-columns: 1fr 2fr 11fr 11fr 2fr 3fr;
    text-align: left;
    font-size: 12px;
    .active{
        background-color: yellow;
    }
    .selected{
        cursor:pointer;
        text-decoration: underline;
        text-align: center;
    }
    td {
        background: #ffffffd9;
        font-size: 12px;
        text-align: left;
        user-select: none;
        .table_btn {
            background-color: #05030e1a;
            margin: 0 3px;
            border: none;
            height: 100%;
            width: 100%;
            border-radius: 4px;
        }
        .table_btn:hover {
            background-color:yellow;
            border-radius: 6px;
        }
        .table_btnm-left {
            margin-left: 6px;
        }
    }
    .title-copy {
        user-select:text;
    }

}

    .table__center  {
        text-align: center;

    }
.table__center-btn {
    display: flex;
    justify-content: center;
}
 
    input {
        width: 100%;
        border: solid 1px rgb(238, 3, 3) ;
    }
}
.prev-next {

    button {
        margin:0 8px;
        border:none;
        svg {
            color: #ff5500;
            height:26px;
            width:26px;
        }
    }
}
.printSetBtn {
    display:flex;
}
.print-btn_wz {
    margin-top: 8px;
    min-width: 60px;
    height: 40px;
    border:none;
    background: top;
    button {
        height: 30px;
        margin-top: 5px;
        padding: 0 2px;
    }
    svg {
        width: 40px;
        height: 30px;
        color: blue;
    }
    span {
        display: block;
        border: 1px black solid;
        padding: 1px 6px;
        background-color: #0d6efd;
        width: 54px;
        height: 29px;
        font-size: 16px;
        margin: 0 auto;
        border-radius:4px;
        color:white;
    }
    
}

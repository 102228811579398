.work__nav {
    width: 22%;
    background: ghostwhite;
    overflow: hidden;
    padding-bottom: 30px;
    height:83vh;
    &-item {
        font-size: 18px;
        font-weight: 700;
        text-decoration: underline;
    }

}
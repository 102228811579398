.packing {
    padding: 0 20vw;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 80vh;
    h2 {
        margin-top: 30px;
        text-decoration: solid;
        font-size: 44px;
        font-weight: 800;
    }
    img {
        width: 100%;
        height: 100%;
    }
    li {
        text-align: left;
        font-size: 28px;
        font-weight: 600;
    }
    h3 {
        font-size: 30px;
        text-decoration: underline;
        margin-bottom: 24px;
    }
}

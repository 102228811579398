.App {
  text-align: center;
  background: honeydew;
  user-select: none;
  overflow: hidden;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media print {
  #your-table-id {
    visibility: visible;
    position: absolute;
    margin: 20px !important;
    top: 2px;
    left: 2px;
    width: 100%;
    td {
      font-size: 12px;
    }
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.spinner-border {
  margin: 12px auto;
  --bs-spinner-width: 16px;
  --bs-spinner-height: 16px;
  cursor: wait;
}
.table_btn-copy .spinner-border {
  margin: 0 16px;
--bs-spinner-width: 16px;
--bs-spinner-height: 16px;
  cursor: wait;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.work {
  display: flex;
  min-height: 80vh;
  border-top: 2px groove #333;
  border-bottom: 2px groove #333;
  border: 1px groove #333;
}
.navigate {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  position: relative;
  cursor: pointer;
  padding-top: 50px;

}
.navigate .active {
  background-color: yellow;
}
.navigate__item {
  display: block;
  position: relative;
  padding: 10px;
  text-align: center;
  background-color: #f4f4f4;
  border: 1px groove #333;
  text-decoration: none;
  color: #333;
  width: 100%;
  font-size: 24px;
  font-weight: 600;
  cursor: pointer;
}
.navigate button:hover {
  background-color: yellow;
}
.info {
  display: flex;
  padding-right: 320px;
  height: 5vh;
}
.info button {
  margin: 0 0 0 10px;
  border: none;
  background: top;
}
.info svg {
  width: 40px;
  height: 40px;
  color: red;
}
.info-timer {
  display: flex;
}
.timer_to-apgrate {
  font-size: 18px;
  font-weight: 700;
  margin: auto;
  width: 260px;
}

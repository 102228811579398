body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
.work__place::-webkit-scrollbar {
  width: 8px;
  background-color: #dee2e6;
}

.work__place::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #0087ff;
}

.work__place::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: #dee2e6;
}
.header-pal::-webkit-scrollbar {
  width: 8px;
  background-color: yellow;
}
.header-pal::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #0087ff;
}
.header-pal::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: #dee2e6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.radio {
    margin-bottom: 20px;
}

.fixed-radio {
    background-color: #ff3c3c;
    margin-right: 30px;
    border-radius: 26px;
}

.scrollable-list {
    overflow-y: auto; /* Дозволяє прокручування, якщо контент перевищує висоту */
    max-height: 240px; /* Налаштуйте максимальну висоту за потребою */
    padding-right: 20px;
}

.scrollable-list::-webkit-scrollbar {
  width: 4px;
  background-color: #f6a605;
}
.scrollable-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #0a58ca;

}
.text-area {
    
    width: 70%;
    background-color: white;
    opacity: 0.9;
    overflow-y: auto;
}
.messages-container {
    height: 400px; /* Встановіть висоту відповідно до потреб */
    overflow-y: auto; /* Дозволяє прокрутку */
    border: 1px solid #ccc; /* Додайте рамку */
    padding: 10px; /* Додайте відступи */
    user-select: text;
}
.text-area::-webkit-scrollbar {
  width: 6px;
  background-color: #f6a605;
}
.text-area_nav {
    display: flex;
    z-index: 998;
    width: 60%;
}
.text-area_ul{
    padding-top:58px ;
}
.text-area_inputbar {
  display: grid;
  grid-template-columns: 8fr 1fr;
  width: 60%;
  position: sticky;
  top: 0;
  background-color: white; /* Можливо, вам захочеться змінити колір фону */
  z-index: 1;
}
.btn-send{
    background-color: white;
    border: none;
}
.btn-send svg {
    height: 50px;
    width: 50px;
    color: blue;
}
.text-area_filter-bar {
  display: flex;
  width: 40%;
}
.text-area_filter-bar button {
    width: 45%;
    font-size: 22px;
    height: 80%;
    margin: 5px 10px 0 10px;
}

.text-area_input {
  margin: 8px 6px 6px 4px;
  width: 98%;
  position: static;
}
.text-area_list {
  text-align: left;
  display: grid;
  grid-template-columns: 12fr 1fr;
  font-size: 24px;
  padding-left: 6px;

}
.text-area_list-btn {
  display: flex;
  font-size: 24px;
  width: 48%;
  max-height: 40px;
}
.text-area_list-btn_date {
    border: none;
}
.text-area_list-date span {
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    
}
.text-area_list-btn button {
  border-style: none;
  opacity: 0.9;
  background-color: white;
  content: none;
}
.text-area_list-btn button::before {
    content: none; /* Видаляємо "before" */
}

.text-area_list-btn button svg {
  height: 32px;
  width: 32px;
}
.text-area_list-item {
  margin: 0;
  position: initial;
  font-size: 24px;
}
.text-area_filter-bar_btn {
  width: 48%;
}

.active {
  background-color: yellow;
}

.header {
  padding: 40px 0 10px 10px;
  display: flex;
  height: 4vh;
  background-image: url("./img/front2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: inherit;
  font-size: 30px;
  position: fixed;
  width: 100%;
  z-index: 999;
  transition: height 0.5s ease;
}
.header_show {
    display: flex;
    background-image: url("./img/front2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    font-size: 30px;
    position: fixed;
    width: 100%;
    z-index: 999;
    padding: 40px 0 10px 10px;
    background-position: bottom ;
    height: 450px;
    transition: height 0.5s ease;
    .text-area_nav{
        position:absolute ;
    }
    .text_area{
        height: 400px;
    }
    .header-pal {
        padding: 18px 0 0 30px;
    }
}

.header-pal {
  margin-left: 30px;
  width: 40%;
  background-color: white;
  opacity: 0.9;
  margin-right: 10px;
  overflow: auto;
}
.active-musik {
    background-color: #ffc107;
    border-radius: 25px;
    margin-right: 10px;

}
.header-pal_input {
  display: flex;
  margin-top:30px;
}

span {
  margin-top: 10px;
  left: 40px;
  font-size: 40px;
}
.palety_header {
  margin-top: 10px;
  text-align: left;
}
.input_header {
  width: 200px;
  height: 40px;
  font-size: 20px;
  text-align: center;
  background-color: honeydew;
}
.btn_kalkulate {
  width: 200px;
  height: 40px;
  font-size: 20px;
  text-align: center;
  margin-left: 10px;
  background-color: yellow;
  width: 130px;
}
.input_form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.input_form-check {
  margin-top: 10px;
}

.input_header-checkbox {
  width: 200px;
  height: 40px;
}
.musik_btn {
  background: top;
  border: none;
}
.header_arrow-show {
    position: absolute;
    top: 0;
    left: 49%;
    color: black;
    cursor: pointer;
}

.musik_btn-box {
  position: absolute;
  top: -3px;
  border: none;
  display: flex;
}
.musik_btn-alarm svg {
  color: yellow;
  height: 38px;
  width: 38px;
}
.musik_btn svg {
  color: red;
  height: 38px;
  width: 38px;
}
.musik_btn-list {
  display: flex;
  background-color: antiquewhite;
  width: 100%;
  margin-bottom: 8px;
  padding-left: 6%;
  border-radius: 18px;
}
.active-list {
  background-color: blue;
  color: white;
}
.musik_btn-list:hover {
  background-color: rgb(57, 70, 247);
}
.last-data {
    text-align: left;
    font-size: 22px;
}
.last-data span {
    font-size: 20px;
    text-transform: uppercase;
}
.last-data .last-title {
    font-size: 16px;
}

.text-area_list-chat_assistant {
    display: block;
    text-align: left;
    font-size: 18px;
}
.text-area_list-chat_user{
    display: block;
    text-align: right;
    font-size: 18px;
}
.text-area_chat {
    display: flex;
    flex-direction: column;
    padding: 58px 10px 0 10px;
    margin: 0;
}

.body-cmr{
    font-family: "DejaVu Sans";
    letter-spacing: -0.3px;
    font-size:5px;
    background-color: white;
    margin: 0 auto;
    width:210mm;
    height:297mm;
    text-align: left;
}
.cmr-body {

    margin: 0 auto;
    .area-adres{
        width: 80%; 
        height: 10mm; 
        font-size: 12px; 
        border:none;
        resize: auto;
    }
    .area-date{
        width:20mm;
        height: 10mm; 
        font-size: 12px; 
        border:none;
        resize: auto;
    }
    table, th, td, tr{
        border-collapse: collapse;
        vertical-align: top;
        border:1.4px solid black;
    }
    
    .sender-details {
        font-size: 12px;
        text-align: left;
        padding-left: 2mm;
        p {
            font-size: 5px;
        }

    }
    .cmr-palsumm{
        padding-top: 10mm;
        font-size: 14px;
        text-align: center;
    }
    .cmr-list {
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 100%;
        height: 47mm;
        align-content: flex-start;
        font-size: 8px;
        li {
            margin: 2px;
            max-width: 26mm;
            font-size: 12px;
        }
    }
}

.top-table {
    width: 203mm;
    height: 282mm;
    margin: 0 auto;
}
tbody {
    display: table-row-group;
    vertical-align: middle;
    unicode-bidi: isolate;
    border-color: inherit;
}

.num{
    font-size: 19px;
    float:left;
    padding-right:10px;
}
.bigNum{
    font-size: 18px;
    float: left;
    margin: 0 10px;
}
.tableInside table{
    width:100%;
    border-collapse:collapse;
    border:1.4px solid black;

}
hr{
    border: 0;
    height: 1px;
    background: black;
}
.cell21{
    font-size:6px;
    line-height:4px;
    float:left;
}
p{
    margin:0px;
    padding:0px;
}
.height-65 {
    height: 65px;
}
.height-150 {
    height: 52mm;
}
.inner-table {
    width: 100%;
}
.height-180 {
    height: 40mm;
}
.cmr-instrukcja {
    width: 50%;
    height: 40mm;
}
.cmr-footer {
    height: 40mm;
}
.cmr-to_adress {
    height:20mm ;
}
.width-400 {
    width: 50%;
}
.width-100{
    width: 100%;
    height: 61mm;
}
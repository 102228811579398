
@media print {
    .wyrob .document {
        @page {
            size: A4;
            margin: 10mm;
      }
    }
    .plan {
      width: 100%;
      height: auto;
      padding: 0;
      margin: 0;
      box-shadow: none;
      background-color: #fff;
      .hide_print {
        display: none;
      }
      .plan_date{
        visibility: visible;
      }
      @page {
        size: A4 landscape;
        margin: 5mm;
      }
    }
  }

.plan {
    width: 98%;
    min-height: 210mm; 
    padding: 2mm; 
    margin: auto;
    box-sizing: border-box;
    position: relative; 
    background-color: #fff;
    .plan_date {
        display: flex;
        text-align: right;
        margin-right: 50mm;
        flex-direction: row;
        justify-content: flex-end;
        .plan_date-input {
            visibility: visible;
            resize: none;
            border: none;
            height: 5mm;
            overflow: hidden;
            padding: 0;
            margin-left: 1mm;
            
        }
    }
    .table{
        table-layout: fixed;
        tr{
            display: grid;
            grid-template-columns: 7fr 7fr 4fr 6fr 2fr 3fr;
            text-align: left;
            font-size: 12px;
            text-align:center;
            .plan_btn {
                margin: 2mm 2mm;

                }
            .fz-12{
            font-size:12px;
            }

            .plan_input {
                min-height:12mm;
            }
            td {
                font-size:16px;
                padding: 2mm 1mm;
                min-height:14mm;
                text-align:center;
                word-wrap: break-word;
                overflow-wrap: anywhere;
            }

        }

    }
}
.wyrob {
    width: 210mm;
    min-height: 297mm;
    padding: 2mm;
    margin: auto;
    box-sizing: border-box;
    position: relative;
    background-color: #fff;
    .print_order {
        padding-top: 50mm;
        font-size: 170px;
        text-align: center;
    }
    .wyrob-body {
        background-color: rgb(128 128 128 / 29%);
        padding: 4mm 2mm;
        border-radius: 7px;
    }
    h2 {
        font-size: 39px;
        text-align: center;
        font-weight: 700;
        margin-top: 27mm;
        margin-bottom: 10mm;
    }
    .wyrob-body_title {
        margin-bottom: 8mm;
        h4 {
            font-size:13px;
            text-align:left;
            font-weight: 700;
            
        }
        .input-title {
            width:100%;
            height:20mm;
            border:none;
            resize:none;
            overflow:hidden;
            border-radius: 6px;
            text-align:center;
            font-size:38px;
            padding:4mm 2mm ;
            font-weight: 600;
        }
        .input-title_isbn {
            height:40mm;
        }

        .name {
            font-size:28px;
        }
    }

    .wyrob-body_item {
        margin: 10mm 0 30mm 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        .last-item {
            margin-right: 73mm;
        }
        .item {
            width:54mm;
            margin-bottom: 2mm;
            h4 {
                font-size:12px;
                text-align:left;
                font-weight: 700;
                height: 6mm;
            }
            .input-item {
                width:100%;
                height: 26mm;
                border:none;
                resize:none;
                overflow:hidden;
                border-radius: 6px;
                padding: 6mm 1mm;
                font-size: 34px;
                text-align: center;
                
            }
            
        }
    }
    .wyrob-body_item-isbn{
        margin: 10mm 0 10mm 0;
    }
}

.document {
    width: 210mm;
    min-height: 297mm;
    margin: auto;
    box-sizing: border-box;
    position: relative;
    padding-bottom: 34mm;
    background-color: #fff;
    padding:1mm;
    .nadawca {
        width: 86mm;
        height: 28mm;
        border: 1.5px black solid;
        resize: none;
        border:none;
        padding:0;

    }
    .nadawca-info {
        border: 1.4px black solid;
        height: 34mm;
        .nadawca-info_title{
            display:flex;
        }
        h4 {
            font-size: 14px;
            width: 53%;
            text-align: left;
            padding: 4px;
        }
        .nadawca-info_nabywca {
            display:block;
            padding-left: 53%;
            background-color: #80808099;
            text-align: left;
            font-size: 17px;
            font-weight: 700;
            border-bottom: 1.4px solid black;
            
        }
        .nadawca-info_nabywca-dane {
            border-left: 1.4px solid black;
            padding: 0 4px;
        }
    }
    textarea{
        border:none;
        height: 26.8mm;
        width: 47%;
        font-size: 16px;
        padding: 6px;
        overflow: hidden;
        resize: none;
        border-left: 1.4px solid black;
        border-bottom: 1.4px solid black;
    }
    .opis{
        height: 15px;
        width: 100%;
        border:none;
        resize:none;
        font-size: 11px;
        overflow: hidden;
        padding:0 2px;
        text-align:center;
    }
    .logo-wz {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    
    }
    
    .logo-adress {
        display: flex;
        flex-direction: column;
        padding-left: 118mm;
        h2 {
            display: block;
            font-size: 18px;
            text-align: left;
            font-weight: 700;
        }
    }
}



h2 {
    font-size: 18px;
    text-align: center;
    font-weight: 700;
}

p {
    font-size: 14px;
    line-height: 1.6;
}

.table-wz {
    display: grid;
    width: 100%;
    margin-top:2mm;
    border-collapse: collapse;
    grid-template-columns: 1fr 5fr 3fr 1fr; /* Задає ширини колонок */
}

.table-wz thead, .table-wz tbody {
    display: contents; /* Забезпечує, що елементи всередині thead і tbody будуть включені в grid-контейнер */
}

.table-wz tr {
    display: contents; /* Залишає рядки прозорими для структури Grid */
    font-size: 12px;
}

.table-wz th, .table-wz td {
    padding: 1px 2px;
    border: 1.4px solid #000;
    display: flex;
    align-items: center;
    font-size: 12px;
}

.table-wz th {
    background-color: #f2f2f2;
    font-weight: bold;
    display: block;
    text-align: center;
}
footer {
    position: absolute;
    bottom: 0;
    left: 4px; /* відступ зліва для відповідності полям сторінки */
    width: 99%;
    height: 30mm; /* висота футера */
    background-color: #fff; /* білий фон, щоб не перекривався вміст */
}
.footer-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;


}
.footer {
    height: 22mm;
    width: 56mm;
    border: 1.5px black solid;
    
    p {
        font-size: 10px;
    }
}
.footer-comment {
    display:block;
    border: 1.5px black solid;
    height: 4mm;
    width: 100%;
    font-size: 10px;
    text-align:center;
}
.table-wz tr {
    page-break-inside: avoid;
}
.table-wz_edit-header{
    .spiner-border {
        width:22px;
        height:22px;
        margin:0;
        padding:0;
        }
    .table-wz_edit {
        margin: 0 auto;
        width:210mm;
        background-color:white;
        user-select: text;
        tr{
            font-size: 12px;
            display: grid;
            border-collapse: collapse;
            grid-template-columns: 1fr 4fr 4fr 2fr 1fr;
            th {
                border: solid 1px black;
                border-collapse: collapse;
                background-color:#00000040;
            }
            td{
                border: solid 1px black;
                min-height: 7mm;
                overflow:hidden;
            }
    
        }
    }


}


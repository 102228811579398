.container {
  .col-md-6 {
    margin-top: 10%;
  }
  .card-body {
    background: #80808045;
  }
  #username {
    margin-bottom: 10px;
  }
  input {
    text-align: center;
  }
}

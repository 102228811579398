.work__item {
  display: flex;
  flex-direction: column;
  height: 70vh;
  width: 96%;
  margin-top:8px;
  position: relative;
  overflow: overlay;
  margin-left: 10px;
  ul{
    margin-right: 6px;
  }
}
.work__item::-webkit-scrollbar {
  width: 6px;
  background-color: #dee2e6;
}

.work__item::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #0a58ca;

}

.work__item::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: #dee2e6;
}
.work__item_title {
  display: block;
  padding: 0;
  list-style: none;
  margin: 0px 0px 14px 0px;
  width: 100%;
  position: relative;
}
.work__manager_list{
    padding: 0;
    margin-left: 4px;
}
